@import 'styles/includes.folkhem.scss';

.StreamField {
    $root: &;

    &__Component {
        margin-bottom: 20px;

        @include media(md) {
            margin-bottom: 120px;
        }

        &:last-child {
            .PageArticle--our-homes & {
                margin-bottom: 0;
            }
        }

        &--MenuBlock {
            margin: 0;
            padding: 0;
        }

        &--Wysiwyg {
            @include u-wrap-folkhem(content);
            margin-bottom: 30px;

            @include media(md) {
                margin-bottom: 120px;
            }

            #{$root}--WithinPageTypeHome & {
                display: flex;
                justify-content: center;
                text-align: center;
            }
        }

        &--WysiwygItem {
            max-width: 618px;
        }

        &--CardImage {
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 120px;
            }
        }

        &--Embed {
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 120px;
            }
        }

        &--CardFact {
            @include u-wrap-folkhem(content);
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 120px;
            }
        }

        &--FormBlock {
            @include u-wrap-folkhem(article);
            margin-bottom: 40px;

            @include media(md) {
                margin-bottom: 120px;
            }
        }

        &--CardMedia {
            @include u-wrap-folkhem(article);
            margin-bottom: 40px;

            @include media(md) {
                margin-bottom: 120px;
            }
        }

        &--ArticleList {
            @include u-wrap-folkhem(article);
            margin-bottom: 40px;
            margin-top: 35px;

            @include media(md) {
                margin-bottom: 120px;
            }
        }

        &--ContactList {
            @include u-wrap(article);
            margin-bottom: 40px;
            margin-top: 35px;

            @include media(md) {
                margin-bottom: 55px;
                padding-bottom: 70px;
            }
        }

        &--Quote {
            @include u-wrap-folkhem(content);
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 120px;

                + .Quote {
                    max-width: 618px;
                }
            }
        }

        &--RelatedProjects {
            @include u-wrap-folkhem(article);
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 120px;
            }
        }

        &--ExternalProjects {
            @include u-wrap-folkhem(article);
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 120px;
            }
        }

        &--CardCta {
            @include u-wrap-folkhem(article);
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 120px;
            }
        }

        &--CodeBlock {
            @include u-wrap-folkhem(content);
            margin-bottom: 0;
        }

        &--Gallery {
            @include u-wrap-folkhem(article);
        }
    }

    &--ArticleWidth {
        .StreamField__Component {
            &--Wysiwyg {
                @include u-wrap-folkhem(article);
            }

            .CardImage {
                &--standard,
                &--normalbleed {
                    @include u-wrap-folkhem(article);
                }
            }
        }
    }
}
