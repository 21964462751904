@import 'styles/includes.nordr.scss';

.StreamField {
    $root: &;

    &__Component {
        margin-bottom: 20px;

        @include media(md) {
            margin-bottom: 40px;
        }

        &:last-child {
            .PageArticle--our-homes & {
                margin-bottom: 0;
            }
        }

        &--MenuBlock {
            margin: 0;
            padding: 0;
        }

        &--ProjectViewingsAndBeforeProjectFacts {
            margin-bottom: 0;
        }

        &--ProjectFactsAndBeforeProjectViewings {
            margin-bottom: 0;
        }

        &--Wysiwyg {
            @include u-wrap(content);
            margin-bottom: 56px;

            @include media(md) {
                margin-bottom: 80px;
            }

            #{$root}--WithinPageTypeHome & {
                display: flex;
                justify-content: center;
                text-align: center;
            }
        }

        &--WysiwygItem {
            max-width: 650px;
            margin: 0 auto;
        }

        &--CardImage {
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 80px;
            }
        }

        &--Embed {
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 80px;
            }
        }

        &--CardFact {
            @include u-wrap(content);
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 80px;
            }
        }

        &--ObjectCostCalculator {
            margin-bottom: 40px;

            @include media(md) {
                margin-bottom: 80px;
            }
        }

        &--FormBlock {
            @include u-wrap(article);
            margin-bottom: 40px;

            @include media(md) {
                margin-bottom: 80px;
            }
        }

        &--CardMedia {
            @include u-wrap(article);
            margin-bottom: 40px;

            @include media(md) {
                margin-bottom: 80px;
            }
        }

        &--ArticleList {
            @include u-wrap(article);
            margin-bottom: 40px;
            margin-top: 35px;

            @include media(md) {
                margin-bottom: 55px;
                padding-bottom: 70px;
            }
        }

        &--ContactList {
            @include u-wrap(article);
            margin-bottom: 40px;
            margin-top: 35px;

            @include media(md) {
                margin-bottom: 55px;
                padding-bottom: 70px;
            }
        }

        &--Quote {
            @include u-wrap(content);
            margin-bottom: 64px;
            margin-top: 102px;

            @include media(md) {
                margin-bottom: 80px;
            }
        }

        &--Stepper {
        }

        &--RelatedProjects {
            @include u-wrap(article);
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 80px;
            }
        }

        &--ExternalProjects {
            @include u-wrap(article);
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 80px;
            }
        }

        &--CardCta {
            @include u-wrap(article);
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 80px;
            }
        }

        &--CodeBlock {
            @include u-wrap(content);
            margin-bottom: 0;
        }

        &--Iframe {
            @include u-wrap(content);
        }

        &--Gallery {
            @include u-wrap(article);
            @include media(lg) {
                padding-left: $gutter + $colWidthLg;
                padding-right: $gutter + $colWidthLg;
            }

            @include media(xl) {
                padding-left: $gutter + $colWidthXl;
                padding-right: $gutter + $colWidthXl;
            }
        }
    }

    &--ArticleWidth {
        .StreamField__Component {
            &--Wysiwyg {
                @include u-wrap(article);
            }

            .CardImage {
                &--normalbleed {
                    @include u-wrap(article);
                }

                &--standard {
                    @include u-wrap(content);
                }
            }
        }
    }
}
